import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from 'gatsby'
import Seo from "../../components/seo";
import Layout from "../../components/layout";
import HeroHome from "../../components/hero-home";
import ZFlow from "../../components/z-flow";
import VisionCarousel from "../../components/vision-carousel";

export default function HomeEs({ location, path, data: { cms: { entry, seo, nav, footer_nav, globalSet, search, team, portfolio_companies } } }) {
  return (
    <Layout theme="light" mobileTheme="dark" nav={nav} footer_nav={footer_nav} global={globalSet} search={search} team={team} portfolio_companies={portfolio_companies} seo={seo} location={location}>
      <Seo
        title={seo.og_title}
        description={seo.og_description}
        meta={[{
          "property": "og:image",
          "content": "/images/og-image-1.jpg"
        }]}
      />

      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "SoftBank Latin America Fund",
          "url": "https://latinamericafund.com",
          "logo": "https://latinamericafund.com/images/og-image-1.jpg",
          "alternateName": "The SoftBank Latin America Fund"
        }
        `}
        </script>
      </Helmet>

			{entry.modules.map((module, index) => {
				switch (module.type) {
					case "hero":
						return (
							<HeroHome
                key={index}
                heading={module.heading}
                headingMeta={module.headingmeta}
                imageAlt={module.imagealt}
                imageUrl={module.image?.publicURL}
                imageFile={module.image?.imageFile}
                video={module.video}
                theme="light" 
                mobileTheme="dark"
							/>
						);
					case "z-flow":
						return (
							<ZFlow 
              key={index}
              sections={module.zflow_items}
              padding_top={module.padding_top}
              />
						);
          case "vision_carousel":
            return (
              <VisionCarousel 
              key={index}
              vc_items={module.vc_items} />
            );
					default:
						return null;
				}
			})}

    </Layout>
  );
}


export const query = graphql`
query HomeEs {
  cms {
    nav(handle: "main_nav") {
      tree(site: "es") {
        page {
          title
          url
          ... on cms_NavPage_MainNav {
            external
          }
        }
        children {
          page {
            title
            url
          }
        }
      }
    }
    footer_nav: nav(handle: "footer_nav") {
      tree(site: "es") {
        page {
          title
          url
        }
        children {
          page {
            title
            url
          }
        }
      }
    }
    globalSet(handle: "footer", site: "es") {
      ... on cms_GlobalSet_Footer {
        copyright
        links {
          ... on cms_Set_Links_Links {
            link_link
            link_label
            link_external
            link_btn_type {
              label
              value
            }
          }
        }
      }
    }
    seo: SeoMeta(page_id: "13020268-968f-4af3-abec-7110a364cde1") {
      og_title
      og_description
      title
      description
      og_url
      rel_canonical
      translations {
        exists
        id
        is_current
        site
        uri
      }
    }
    entry(id: "13020268-968f-4af3-abec-7110a364cde1") {
      ... on cms_Entry_Pages_PageModular {
        id
        url
        uri
        title
        slug
        permalink
        modules {
          ... on cms_Set_Modules_Hero {
            type
            heading
            headingmeta
            imagealt
            image {
              ...CMSAsset
            }
            video
          }
          ... on cms_Set_Modules_ZFlow {
            type
            padding_top
            zflow_items {
              ... on cms_Set_ZflowItems_Item {
                heading
                body_copy
                type
                eyebrow
                media_caption
                item_style {
                  value
                  label
                }
                item_alignment {
                  value
                  label
                }
                image_ratio {
                  label
                  value
                }
                media {
                  ...CMSAsset
                }
              }
            }
          }
          ... on cms_Set_Modules_VisionCarousel {
            type
            vc_items {
              ... on cms_Set_VcItems_Item {
                item_title
                quote
                link
                link_label
                type
              }
            }
          }
        }
      }
    }
    search: globalSet(handle: "search", site: "es") {
      ... on cms_GlobalSet_Search {
          handle
          results_text
          no_results_subtext
          no_results_text
          search_input_field
          companies_headline
          investors_headline
          suggested_search_terms {
          name
        }
        site {
          handle
          name
        }
      }
    },
    team: entries(collection: "team", limit: 3000, filter: {site: "es"}) {
      to
      data {
        id
        ... on cms_Entry_Team_Team {
          __typename
          id
          title
          published
          site {
            short_locale
            locale
            handle
            name
    
          }
          slug
          preview_image {
            ... on cms_Asset_Assets {
            id
            alt
            imageFile {
              publicURL
              extension
              childImageSharp {
              gatsbyImageData(width: 90, height: 90, formats: [AUTO, WEBP, AVIF])
              }
              absolutePath
              atime
              base
            }
            path
            }
          }
          sectors {
            ... on cms_Term_Sectors_Sectors {
            id
            title
            }
          }
          region {
            id
            ... on cms_Term_Region_Region {
            id
            title
            }
          }
        }
      }
      total
      from
      has_more_pages
    }
    portfolio_companies: entries(collection: "companies", sort: "title", filter: {site: "es"}) {
      to
      data {
        id
        slug
        ... on cms_Entry_Companies_Companies {
          __typename
          id
          title
          slug
          founder_label
          founders
          company_url
          description
          status
          status_override
          company_status {
            id
            slug
            title
          }
          site {
            short_locale
            locale
            handle
            name
    
          }
          team {
            hide_on_portfolio_page
            team_member {
              id
              title
            }
          }
          region {
            ... on cms_Term_Region_Region {
              id
              title
              slug
            }
          }
          sectors {
            ... on cms_Term_Sectors_Sectors {
              id
              title
              slug
            }
          }
          logo {
            ... on cms_Asset_Assets {
            id
            alt
            imageFile {
              publicURL
              extension
              childImageSharp {
              gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
              }
              absolutePath
              atime
              base
            }
            path
            }
            url
            path
          }
        }
      }
      total
      from
      has_more_pages
    }
  }

}

`